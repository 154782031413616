<template>
  <div>
    <h1 class="col-sm-12 text-right mt-4 mb-4">Catálogos</h1>

    <DataGrid :config="dataGridConfig" :data="catalogos" :select="seleccionar" @actions="dataGridActions">
      <div class="row">
        <div class="col-sm-12">
          <button class="btn btn-warning mr-2" @click="obtener_catalogos">Recargar</button>
          <button class="btn btn-success mr-2" @click="modal_detalle=true">Crear catálogo</button>
          <button class="btn btn-primary mr-2" @click="editar_catalogo">Editar catálogo</button>
          <button class="btn btn-danger" @click="eliminar_catalogo">Eliminar catálogo</button>
        </div>
      </div>
    </DataGrid>

    <Detalle v-if="modal_detalle" :catalogo="catalogo" @update="cerrar_actualizar_modal_detalle" @close="cerrar_modal_detalle" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Detalle from './Catalogo/Detalle'

  import api from './../api/catalogos'

  export default {
    components: {
      DataGrid, Detalle
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'catalogos'
          ,cols: {
            nombre: 'Nombre'
            ,codigo: 'Código'
            ,descripcion: 'Descripción'
            ,created_at: 'Creación'
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,catalogos: []
        ,catalogo: {
          nombre: null
          ,codigo: null
          ,descripcion: null
          ,opciones: []
        }
        ,modal_detalle: false
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        if (tipo == 'options') {
          this.options = data;
          this.obtener_catalogos();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      },
      obtener_catalogos: async function() {
        try {
          let res = (await api.obtener_catalogos(this.options)).data;

          this.catalogos = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,editar_catalogo: function() {
        if (this.seleccionadas.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar un catálogo','error','alert');

        this.catalogo = this.seleccionadas[0];

        this.modal_detalle = true;
      }
      ,eliminar_catalogo: async function() {
        if (this.seleccionadas.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos un catálogo a eliminar','error','alert');

        try {
          for (let i=0; i<this.seleccionadas.length; i++) {
            let res = (await api.eliminar_catalogo(this.seleccionadas[i].id)).data;
            this.$log.info('res',res);
          }
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }

        this.seleccionar = false;
        this.obtener_catalogos();
      }
      ,cerrar_modal_detalle: function() {
        this.modal_detalle = false;
        this.seleccionar = false;
        this.catalogo = {
          nombre: null
          ,codigo: null
          ,descripcion: null
          ,opciones: []
        }
      }
      ,cerrar_actualizar_modal_detalle: function() {
        this.cerrar_modal_detalle();
        this.obtener_catalogos();
      }
    }
  }
</script>